import React from "react";
import * as constants from "../../../constants";

export default ({ image, alt, width, height }) => {
  /*
  if (!image) {
    return null;
  }

  let path = image.path;

  if (!path) {
    return null;
  }

  if (path[0] === "/") {
    path = path.slice(1, path.length);
  }
  */
  let path = image.path;
  path = `${constants.COCKPIT_URL}${path}`;

  return (
    <img
      src={path}
      alt={alt}
      width={width || "100%"}
      height={height || "100%"}
    />
  );
};
