import React, { useEffect, useContext, useState } from "react";
import clsx from "clsx";
import { makeStyles, Box, Grid, Typography } from "@material-ui/core";
import * as constants from "../constants";
import SidenavContext from "../utils/SidenavContext";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  wrapper: {
    minHeight: "calc(100vh - 310px)"
  },
  img: {
    width: "100%",
    height: "auto",
    marginTop: theme.spacing(2)
  },
  info: {
    fontSize: "1rem"
  },
  link: {
    fontSize: "1rem",
    marginRight: theme.spacing(2)
  }
}));

const Footer = ({ contactInfo, contactImage, isVisible }) => {
  const classes = useStyles();
  const {
    currentSidenavItem,
    currentSidenavBecameInactive,
    currentSidenavBecameActive
  } = useContext(SidenavContext);

  useEffect(() => {
    if (isVisible) {
      currentSidenavBecameActive("#kontakt");
    } else {
      currentSidenavBecameInactive("#kontakt");
    }
  }, [isVisible]);

  const createMarkup = html => {
    return { __html: html };
  };

  return (
    <>
      <div className={clsx(classes.wrapper)} id="kontakt">
        <Box
          pl={{ xs: 0, lg: 50, xl: 80 }}
          pb={20}
          pt={5}
          pr={{ xs: 0, lg: 15 }}
        >
          <Box p={3}>
            <Grid container>
              <Grid item xs={12} md={3}>
                <Box
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                    className={classes.info}
                  >
                    <div dangerouslySetInnerHTML={createMarkup(contactInfo)} />
                  </Typography>
                  <div>
                    <Link to="/impressum" className={classes.link}>
                      Impressum
                    </Link>
                    <Link to="/datenschutz" className={classes.link}>
                      Datenschutz
                    </Link>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                {contactImage && contactImage.path && (
                  <img
                    src={constants.BACKEND_URL + "/" + contactImage.path}
                    alt={contactImage.name}
                    className={classes.img}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Footer;
