import React from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as constants from "../../../constants";

const useStyles = makeStyles(theme => ({
  title: {
    margin: constants.THEME.spacing(2, 8)
  },
  actionWrapper: {
    padding: constants.THEME.spacing(2),
    textAlign: "center"
  }
}));

export default ({ title, button_text, url }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h2" align="center" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.actionWrapper}>
        <Button component="a" href={url} variant="contained" color="secondary">
          {button_text}
        </Button>
      </div>
    </>
  );
};
