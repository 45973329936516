import React, { useState } from "react";
import KaDialog from "./KaDialog";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Divider,
  TextField,
  Button,
  IconButton,
  Fab
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";
import { grey, green, pink } from "@material-ui/core/colors";
import API from "../../utils/APIcontact";
import CheckIcon from "@material-ui/icons/Check";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "auto 80%",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat"
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  dividerTransp: {
    height: 0,
    margin: theme.spacing(0.5, 0)
  },
  link: {
    color: "inherit",
    fontWeight: "bold",
    textDecoration: "none"
  },
  check: {
    color: green[500],
    width: 40,
    height: 40
  },
  iconButton: {
    marginLeft: "auto"
  },
  field: {
    background: "white"
  },
  openBtn: {
    position: "fixed",
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    zIndex: 9999
  },
  textLink: {
    color: "rgba(255,255,255,0.8) !important"
  }
}));

const initialFormState = {
  vorname: "",
  nachname: "",
  email: "",
  telefon: "",
  nachricht: ""
};

const Beratungstermin = ({ className }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();
  const [state, setState] = useState(initialFormState);
  const [success, setSuccess] = useState(null);

  const handleChange = name => event => {
    if (event.target.hasOwnProperty("checked")) {
      setState({ ...state, [name]: event.target.checked });
    } else {
      setState({ ...state, [name]: event.target.value });
    }
  };

  const handleSend = async () => {
    const body = {
      Vorname: state.vorname,
      Nachname: state.nachname,
      "E-Mail": state.email,
      Telefon: state.telefon,
      Nachricht: state.nachricht
    };
    try {
      const resultData = await API.post("", body);
      const data = resultData.data ? resultData.data : {};
      setSuccess(data);
    } catch (e) {
      console.log("FEHLER: ", e);
    }
  };

  const close = () => {
    setState(initialFormState);
    setSuccess(null);
    setOpen(false);
  };

  // console.log("SUCCESS", success);

  const successView = (
    <>
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          width={60}
          height={60}
          bgcolor="white"
          borderRadius={30}
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={4}
        >
          <CheckIcon className={classes.check} />
        </Box>
        <Typography variant="h6" gutterBottom>
          Vielen Dank für Ihre Nachricht!
        </Typography>
        <Typography variant="body2">
          Wir setzen uns mit Ihnen in Verbindung
        </Typography>
      </Box>
    </>
  );

  const valid = state.vorname && state.nachname && state.email && state.telefon;

  const formView = (
    <>
      <TextField
        className={classes.field}
        value={state.vorname}
        onChange={handleChange("vorname")}
        fullWidth
        label="Vorname"
        margin="dense"
        variant="outlined"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        className={classes.field}
        value={state.nachname}
        onChange={handleChange("nachname")}
        fullWidth
        label="Nachname"
        margin="dense"
        variant="outlined"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        className={classes.field}
        type="email"
        value={state.email}
        onChange={handleChange("email")}
        fullWidth
        label="E-Mail-Adresse"
        margin="dense"
        variant="outlined"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        className={classes.field}
        value={state.telefon}
        onChange={handleChange("telefon")}
        fullWidth
        label="Rückruf-Telefonnummer"
        margin="dense"
        variant="outlined"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        className={classes.field}
        value={state.nachricht}
        onChange={handleChange("nachricht")}
        fullWidth
        label="Ihre Nachricht"
        multiline
        rows={10}
        margin="dense"
        variant="outlined"
      />
      <Divider className={classes.dividerTransp} />
      <Box
        display="flex"
        flexDirection={xs || sm ? "column" : "row"}
        alignItems="center"
      >
        <Typography variant="caption" gutterBottom={xs || sm}>
          Mit Klick auf „absenden“ stimmen Sie unseren{" "}
          <a className={classes.link} href="/datenschutz" target="_blank">
            Datenschutzbestimmungen
          </a>{" "}
          zu.
        </Typography>
        <Button
          fullWidth={xs || sm}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSend}
          disabled={!valid}
        >
          Absenden
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <Fab
        className={classes.openBtn}
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EmailIcon />
      </Fab>

      <KaDialog open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ xs: 0, sm: 3 }}
            height={xs ? undefined : sm ? 780 : 700}
          >
            <Box
              display="flex"
              flexDirection="column"
              color="white"
              mr={{ xs: 0, sm: 3 }}
              flexGrow={1}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
            >
              <Box
                p={4}
                bgcolor={theme.palette.primary.main}
                flexGrow={1}
                flexShrink={1}
                flexBasis={xs ? "auto" : "0px"}
              >
                <Typography variant="h3" component="h3">
                  Kontakt
                </Typography>
                <Typography variant="h6" component="h3" gutterBottom>
                  Haben Sie Fragen oder möchten Sie einen persönlichen
                  Beratungstermin vereinbaren? Senden Sie uns eine Mail oder
                  rufen Sie uns an. Wir freuen uns auf Sie!
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  wamedia IT Concept
                  <br />
                  Europaallee 10
                  <br />
                  67657 Kaiserslautern
                  <br />
                  Telefon: 0631 343586-90
                  <br />
                  E-Mail:{" "}
                  <a
                    className={classes.textLink}
                    href="mailto:info@wamedia-itc.de"
                  >
                    info@wamedia-itc.de
                  </a>
                </Typography>
              </Box>
            </Box>
            <Box
              p={3}
              className={classes.img}
              flexGrow={1}
              bgcolor={grey[200]}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
            >
              {Boolean(success) ? successView : formView}
            </Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default Beratungstermin;
