import React, { useEffect, useState } from "react";
import PageComponents from "../PageComponents";
import COCKPIT_API from "../../utils/API";
import { Container, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as constants from "../../constants";
import convert from "htmr";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4, 0, 8, 0)
  },
  paper: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4)
    }
  },
  title: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 20),
      marginTop: theme.spacing(8)
    }
  },
  text: {
    fontSize: 16,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      fontSize: 20
    }
  }
}));

const Page = ({ match }) => {
  const classes = useStyles();
  const [components, setComponents] = useState([]);
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const body = {
          filter: { slug: match.params.page },
          limit: 1
        };
        const pageData = await COCKPIT_API.post(
          "collections/get/pages?token=" + constants.API_TOKEN,
          body
        );

        // Title
        const titleData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].title
            : null;
        setTitle(titleData);

        // Description
        const descriptionData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].subtitle
            : null;
        setSubtitle(descriptionData);

        // Components
        const componentsData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].content
            : [];
        setComponents(componentsData);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, [match.params.page]);

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Typography variant="h1" align="center" className={classes.title}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color="textPrimary"
            component="div"
            className={classes.text}
          >
            {subtitle && convert(subtitle)}
          </Typography>
          <Paper className={classes.paper}>
            <PageComponents components={components || []} />
          </Paper>
        </Container>
      </div>
      <Helmet>
        <title>wamedia IT Concept - {Boolean(title) ? title : ""}</title>
      </Helmet>
    </>
  );
};

export default Page;
