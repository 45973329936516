import React, { useEffect, useState, useContext } from "react";
import Projekt from "./Projekt";
import TrackVisibility from "react-on-screen";
import { Box } from "@material-ui/core";
import API from "../utils/API";
import AnimationContext from "../utils/AnimationContext";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({}));

const Projekte = () => {
  const [projects, setProjects] = useState([]);
  const { isAnimated } = useContext(AnimationContext);
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.get(`/collections/get/projects`);
        const data = resultData.data ? resultData.data : {};
        setProjects(data.entries ? data.entries : []);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  return (
    <div id="projekte">
      {projects
        .filter(f => f.published)
        .map((item, i) => (
          <Box py={{ xs: 0 }} key={item._id}>
            <TrackVisibility partialVisibility>
              <Projekt item={item} count={i} />
            </TrackVisibility>
          </Box>
        ))}
    </div>
  );
};

export default Projekte;
