import React, { useContext } from "react";
import {
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import Astronaut from "../Astronaut";
import Team from "../Team";
import SideMenu from "../SideMenu";
import TrackVisibility from "react-on-screen";
import Projekte from "../Projekte";
import AnimationContext from "../../utils/AnimationContext";
import clsx from "clsx";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  sideMenu: {
    position: "fixed",
    left: 98,
    top: 340,
    zIndex: 1,
    opacity: 1,
    transition: "all 0.3s ease-in"
  },
  sideMenuOut: {
    opacity: 0,
    left: -300
  }
}));

const Home = ({ settings }) => {
  const classes = useStyles();
  const { isAnimated } = useContext(AnimationContext);
  const { xs, sm, md, lg, xl } = useScreensize();

  return (
    <div className={classes.wrapper}>
      <Astronaut
        title={settings && settings.intro_title}
        text={settings && settings.intro_text}
      />
      {!xs && !sm && !md && (
        <div
          className={clsx(classes.sideMenu, isAnimated && classes.sideMenuOut)}
        >
          <SideMenu />
        </div>
      )}
      <Projekte />
      <TrackVisibility partialVisibility>
        <Team />
      </TrackVisibility>
    </div>
  );
};

export default Home;
