import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";

export const THEME = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#156db1"
      },
      background: {
        default: "#fff"
      }
    },
    typography: {
      fontFamily: "brandon-grotesque",
      h1: {
        fontSize: "3.2rem",
        textTransform: "uppercase",
        fontWeight: "bold",
        letterSpacing: 4
      },
      h2: {
        fontSize: "3rem",
        fontWeight: "bold"
      },
      h3: {
        fontSize: "2rem",
        textTransform: "uppercase"
      },
      h5: {
        textTransform: "uppercase",
        letterSpacing: 1
      },
      h6: {
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: "1rem"
      },
      body2: {
        fontSize: "1.3rem"
      },
      overline: {
        letterSpacing: 1,
        fontWeight: "bold",
        lineHeight: 1.2
      }
    }
  })
);
