import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import useScreensize from "../../hooks/useScreensize";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(3, 0, 0, 0),
    color: "white",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0)
    }
  },
  title: {
    fontSize: "1.3rem",
    paddingRight: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem"
    }
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box
        px={xs ? 2 : 4}
        display="flex"
        alignItems="center"
        height={76}
        bgcolor={theme.palette.primary.main}
      >
        <Typography variant="h3" component="h2" className={classes.title}>
          {children}
        </Typography>
      </Box>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: "0 !important"
  }
}))(MuiDialogContent);

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    background: "rgba(255,255,255,0.8)",
    "&:hover": {
      background: "white"
    },
    [theme.breakpoints.down("xs")]: {
      top: theme.spacing(2),
      right: theme.spacing(2)
    }
  },
  content: {
    padding: 0
  }
}));

const KaDialog = ({ open, close, title, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const handleClose = () => {
    close();
  };

  return (
    <>
      <Dialog
        style={{ zIndex: 99999 }}
        fullScreen={xs}
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {Boolean(title) && (
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title}
          </DialogTitle>
        )}

        <DialogContent className={classes.content}>{children}</DialogContent>
        {handleClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </Dialog>
    </>
  );
};

export default KaDialog;
