import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import * as constants from "./constants";
import { CssBaseline } from "@material-ui/core";
import Layout from "./components/Layout";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./components/pages/Home";
import Page from "./components/pages/Page";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import "react-image-lightbox/style.css";
import ScrollMemory from "react-router-scroll-memory";
import API from "./utils/API";
import SidenavContext from "./utils/SidenavContext";
import AnimationContext from "./utils/AnimationContext";

function App() {
  const [settings, setSettings] = useState(null);
  const [currentSidenavItem, setCurrentSidenavItem] = useState(["#projekte"]);
  const [isProjectAnimated, setIsProjectAnimated] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.post(`/singletons/get/WebsiteSettings`);
        const data = resultData.data ? resultData.data : {};
        setSettings(data);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  const currentSidenavBecameActive = name => {
    const c = currentSidenavItem.slice();
    if (c[c.length - 1] === name) {
      return;
    }
    c.push(name);
    setCurrentSidenavItem(c);
  };

  const setIsAnimated = val => {
    if (val) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
    setIsProjectAnimated(val);
  };

  const currentSidenavBecameInactive = name => {
    const c = currentSidenavItem.slice();
    if (c[c.length - 1] !== name) {
      return;
    }
    c.splice(-1, 1);
    setCurrentSidenavItem(c);
  };

  return (
    <BrowserRouter>
      <ScrollMemory />
      <ThemeProvider theme={constants.THEME}>
        <CssBaseline />
        <AnimationContext.Provider
          value={{
            isAnimated: isProjectAnimated,
            setIsAnimated
          }}
        >
          <SidenavContext.Provider
            value={{
              currentSidenavItem,
              currentSidenavBecameActive,
              currentSidenavBecameInactive
            }}
          >
            <Layout settings={settings}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={props => <Home settings={settings} {...props} />}
                />
                <Route path="/:page" component={Page} />
              </Switch>
            </Layout>
          </SidenavContext.Provider>
        </AnimationContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
