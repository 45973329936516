import React, { useEffect, useState, useContext } from "react";
import { useScrollPosition } from "../hooks/useScrollPosition";
import { useWindowSize } from "../hooks/useWindowSize";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { grey } from "@material-ui/core/colors";
import SidenavContext from "../utils/SidenavContext";

const useStyles = makeStyles(theme => ({
  sideMenu: {
    opacity: 1,
    transition: "opacity 3s, transform 1s",
    transform: "translateY(-40px)"
  },
  withBg: {
    background: "#fff"
  },
  hide: {
    opacity: 0,
    transition: "opacity .25s, transform .25s",
    transform: "translateY(0)"
  },
  link: {
    color: grey[700]
  },
  active: {
    fontWeight: "bold",
    color: grey[800]
  }
}));

const menuItems = [
  { title: "Projekte", href: "#projekte" },
  { title: "Wir", href: "#wir" },
  { title: "Kontakt", href: "#kontakt" }
];

const SideMenu = () => {
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const classes = useStyles();
  const [wWidth, wHeight] = useWindowSize();
  const { currentSidenavItem } = useContext(SidenavContext);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = Math.abs(currPos.y) / wHeight < 0.6;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll, wHeight]
  );

  return (
    <>
      <List
        dense
        className={clsx(
          classes.sideMenu,
          hideOnScroll && classes.hide,
          currentSidenavItem[currentSidenavItem.length - 1] === "#kontakt" &&
            classes.withBg
        )}
      >
        {menuItems.map(item => (
          <ListItem
            component={AnchorLink}
            href={item.href}
            offset="-40"
            key={item.href}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.link,
                    currentSidenavItem[currentSidenavItem.length - 1].indexOf(
                      item.href
                    ) === 0 && classes.active
                  )}
                  variant="h3"
                >
                  {item.title}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SideMenu;
