import React from "react";
import Column from "./Column";
import { Grid, Hidden } from "@material-ui/core";

export default ({ name, columns }) => {
  return (
    <>
      <Hidden xsDown>
        <Grid container spacing={5}>
          {columns &&
            columns.map((column, idx) => (
              <Column {...column} cols={columns.length} key={`key-${idx}`} />
            ))}
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid container spacing={1}>
          {columns &&
            columns.map((column, idx) => (
              <Column {...column} cols={columns.length} key={`key-${idx}`} />
            ))}
        </Grid>
      </Hidden>
    </>
  );
};
