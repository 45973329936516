import React from "react";
import PageComponents from "../../PageComponents";
import { Grid } from "@material-ui/core";

export default ({ children, cols }) => {
  let sm = 12;
  let md = 12;
  if (cols === 2) {
    sm = 6;
    md = 6;
  } else if (cols === 3) {
    sm = 6;
    md = 4;
  } else if (cols === 4) {
    sm = 6;
    md = 3;
  }
  return (
    <Grid item xs={12} sm={sm} md={md}>
      <PageComponents components={children} />
    </Grid>
  );
};
