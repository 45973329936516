import React, { useEffect, useState, useContext } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import Rocket from "./../images/rocket.png";
import clsx from "clsx";
import API from "../utils/API";
import * as constants from "../constants";
import SidenavContext from "../utils/SidenavContext";

const useStyles = makeStyles(theme => ({
  wrapper: {
    minHeight: "calc(100vh - 30px)",
    opacity: 0,
    transform: "translateY(100px)",
    transition: "all 1s",
    background: "url(" + Rocket + ") no-repeat right bottom",
    backgroundSize: "auto 185px",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "auto 125px"
    },
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20)
  },
  img: {
    width: 230,
    height: "auto",
    maxWidth: "90%",
    [theme.breakpoints.down("md")]: {
      width: 175
    },
    [theme.breakpoints.down("sm")]: {
      width: 150,
      marginBottom: theme.spacing(2)
    }
  },
  isVisible: {
    opacity: 1,
    transform: "translateY(0)"
  }
}));

const Team = ({ isVisible }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const [teamMembers, setTeamMembers] = useState([]);
  const {
    currentSidenavItem,
    currentSidenavBecameInactive,
    currentSidenavBecameActive
  } = useContext(SidenavContext);

  useEffect(() => {
    if (isVisible) {
      if (!show) {
        setShow(true);
      }
      currentSidenavBecameActive("#wir");
    } else {
      currentSidenavBecameInactive("#wir");
    }
  }, [isVisible]);

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.get(`/collections/get/team`);
        const data = resultData.data ? resultData.data : {};
        setTeamMembers(data.entries ? data.entries : []);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  const createMarkup = html => {
    return { __html: html };
  };

  return (
    <div
      className={clsx(classes.wrapper, show && classes.isVisible)}
      id="wir"
      name="team"
    >
      <Box pl={{ xs: 0, lg: 50, xl: 80 }} pb={20} pt={0} pr={{ xs: 0, lg: 15 }}>
        {teamMembers.map(member => (
          <Box p={3} key={member._id}>
            <Grid container>
              <Grid item xs={12} md={3}>
                {member.image.path && (
                  <img
                    src={constants.BACKEND_URL + "/" + member.image.path}
                    alt={member.name}
                    className={classes.img}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="h3" color="textSecondary">
                  {member.name}
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <div
                    dangerouslySetInnerHTML={createMarkup(member.description)}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Team;
