import React, { useState, useContext } from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import AstronautImg from "./../images/astronaut.png";
import grey from "@material-ui/core/colors/grey";
import { useWindowSize } from "../hooks/useWindowSize";
import { useScrollPosition } from "../hooks/useScrollPosition";
import clsx from "clsx";
import useScreensize from "../hooks/useScreensize";
import AnimationContext from "../utils/AnimationContext";

const useStyles = makeStyles(theme => ({
  header: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "calc(100vh - 64px)",
    display: "flex",
    alignItems: "flex-end",
    opacity: 1,
    transition: "opacity 0.3s",
    zIndex: 9999,
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
      height: "auto",
      display: "block",
      paddingTop: "40vh",
      zIndex: 0,
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.only("sm")]: {
      [`@media (orientation: portrait)`]: {
        paddingTop: "60vh"
      }
    }
  },
  hide: {
    opacity: 0
  },
  wrapper: {
    width: "100%",
    boxSizing: "content-box",
    paddingBottom: "53.06%",
    height: 0,
    position: "absolute",
    left: "50%",
    top: "calc(-26.53vw + 18px)",
    pointerEvents: "none",
    transition: "transform 0.5s ease-out",
    animationName: "$waver",
    animationDuration: "6s",
    animationTimingFunction: "cubic-bezier(.48,.19,.66,.79)",
    animationIterationCount: "infinite",
    animationDirection: "alternate-reverse",
    [theme.breakpoints.down("sm")]: {
      [`@media (orientation: portrait)`]: {
        left: 0,
        top: 40
      }
    }
  },
  astronaut: {
    background: "url(" + AstronautImg + ") no-repeat left bottom",
    backgroundSize: "50%",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    pointerEvents: "none",
    transition: "transform 0.5s ease-out",
    [theme.breakpoints.down("sm")]: {
      [`@media (orientation: portrait)`]: {
        backgroundSize: "100%"
      }
    }
  },
  titleItem: {
    marginRight: theme.spacing(2)
  },
  indicatorWrapper: {
    position: "relative"
  },
  indicator: {
    position: "absolute",
    animationName: "$run",
    animationDuration: "3s",
    animationTimingFunction: "ease-in",
    animationIterationCount: "infinite"
  },
  "@keyframes run": {
    from: { top: -50, opacity: 0 },
    to: { top: "100%", opacity: 1 }
  },
  "@keyframes waver": {
    from: { transform: "scale(1) rotate(0deg)" },
    to: { transform: "scale(0.95) rotate(5deg)" }
  }
}));

const Astronaut = ({ title, text }) => {
  const classes = useStyles();
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const [hideOnScroll, setHideOnScroll] = useState(false);
  const [wWidth, wHeight] = useWindowSize();
  const { xs, sm, md, lg, xl } = useScreensize();
  const { isAnimated } = useContext(AnimationContext);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = Math.abs(currPos.y) / wHeight > 0.5;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll, wHeight]
  );

  const onMouseMove = e => {
    const winW = window.screen.width;
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    // setCoords({ x, y });
    if (x < winW / 2 && !xs) {
      return;
    }

    let rotation = ((x - winW / 2) / (winW / 2)) * -40;
    let scaleFactor = ((x - winW / 2) / (winW / 2)) * 0.5;

    if (xs) {
      rotation = ((x - winW) / winW) * -20;
      scaleFactor = ((x - winW) / winW) * 0.4;
      setRotate(rotation);
      setScale(1.5 + scaleFactor);
    } else {
      setRotate(rotation);
      setScale(1 + scaleFactor);
    }
  };

  const createMarkup = html => {
    return { __html: html };
  };

  return (
    <>
      <div
        className={clsx(
          classes.header,
          hideOnScroll && !xs && !sm && classes.hide
        )}
        onMouseMove={onMouseMove}
      >
        <div className={classes.wrapper}>
          <div
            className={classes.astronaut}
            style={{
              transform: "scale(" + scale + ") rotate(" + rotate + "deg)"
            }}
          ></div>
        </div>
        <Box pl={{ xs: 3, md: 7, lg: 14.5 }} pr={{ xs: 2, md: 0 }}>
          <Typography variant="h1">
            {title &&
              title.split(" ").map((t, i) => (
                <React.Fragment key={i}>
                  <span className={classes.titleItem}>{t}</span>{" "}
                </React.Fragment>
              ))}
          </Typography>
          <Box width={xs || sm ? "100%" : md ? "70vw" : "50vw"}>
            <Typography variant="body2" component="div" color="textSecondary">
              {text && <div dangerouslySetInnerHTML={createMarkup(text)} />}
            </Typography>
          </Box>
          {!xs && !sm && (
            <Box pt={10} height="180px" display="flex">
              <Box
                width="6px"
                bgcolor={grey[200]}
                mr={2}
                overflow="hidden"
                className={classes.indicatorWrapper}
              >
                <Box
                  width="6px"
                  height="40px"
                  bgcolor={grey[500]}
                  className={classes.indicator}
                ></Box>
              </Box>
              <Typography variant="h6">Entdecken</Typography>
            </Box>
          )}
        </Box>
      </div>
    </>
  );
};

export default Astronaut;
